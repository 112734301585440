import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchTenants as fetchTenantsRequest } from "../../services/api.service";
import { setToLocalStorage, getFromLocalStorage } from "../../utils";
import { getFirstAvailableTenant } from "../../services/tenant.service";

import { keyBy } from "lodash";

const fetchTenantsThunk = createAsyncThunk(
  "tenants/fetchAll",
  async (searchQuery, thunkAPI) => {
    const tenants = await fetchTenantsRequest(searchQuery);
    thunkAPI.dispatch(setTenantsShown(true));
    return tenants;
  }
);

const tenantsSlice = createSlice({
  name: "tenants",
  initialState: {
    byId: null,
    isLoading: false,
    hasError: false,
    selectedTenant: null,
    isFirstRender: true,
  },
  reducers: {
    selectTenant(state, action) {
      state.selectedTenant = action.payload;
      setToLocalStorage(`selectedTenant`, action.payload);
      state.isTenantsShown = false;
    },
    setTenantsShown(state, action) {
      state.isTenantsShown = action.payload;
    },
    setIsFirstTenantsRender(state, action) {
      state.isFirstRender = action.payload;
    },
  },
  extraReducers: {
    [fetchTenantsThunk.pending](state) {
      state.byId = null;
      state.isLoading = true;
      state.hasError = false;
    },
    [fetchTenantsThunk.fulfilled](state, action) {
      state.isLoading = false;
      state.hasError = false;
      state.byId = keyBy(action.payload.tenants, "id");
      const { tenants } = action.payload;
      state.selectedTenant =
        state.isFirstRender &&
        (getFromLocalStorage("selectedTenant") ||
          getFirstAvailableTenant(tenants));
    },
    [fetchTenantsThunk.rejected](state) {
      state.byId = null;
      state.isLoading = false;
      state.hasError = true;
    },
  },
});

export { fetchTenantsThunk as fetchTenants };

const { actions, reducer } = tenantsSlice;

export const { selectTenant, setTenantsShown, setIsFirstTenantsRender } =
  actions;
export default reducer;
