import React, { useState, useCallback, useEffect, useRef } from "react";
import { debounce } from "lodash";
import { Modal, ModalBody, ModalFooter, Alert } from "reactstrap";

import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
  CloseIcon,
  AddCustomerIcon,
  LoaderIcon,
} from "../../../../assets/icons";
import classes from "../../../../styles/modal.module.scss";
import formClasses from "../../../../styles/input.module.scss";
import "../../../../custom-modal.css";
import {
  createCustomer,
  fetchSteerCustomers,
  setCustomerError,
} from "../../../customers/customers.slice";
import {
  getSteerNotAddedCustomers,
  isSteerCustomersLoading,
  getCustomerError,
  getIsCustomersLoading,
} from "../../../customers/customers.selectors";
import SelectInput from "../../../../components/ui/select-input/SelectInput";
import alertClasses from "../../../../styles/alert.module.scss";

export function CustomerModal({ isOpen, toggle }) {
  const dispatch = useDispatch();
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const steerNotAddedCustomers = useSelector(getSteerNotAddedCustomers);
  const isLoading = useSelector(isSteerCustomersLoading);
  const customerError = useSelector(getCustomerError);
  const isAddCustomersPending = useSelector(getIsCustomersLoading);

  const steerCustomerSearchQuery = useRef("");

  useEffect(() => {
    if (isOpen) dispatch(fetchSteerCustomers());
  }, [isOpen]);

  const onToggle = () => {
    toggle();
    dispatch(setCustomerError(""));
  };

  const onSubmit = (data) => {
    if (!isAddCustomersPending) {
      dispatch(setCustomerError(""));
      const { id: steerCustomerId } = data.customer;
      if (!steerCustomerId) return;
      dispatch(createCustomer({ steerCustomerId: steerCustomerId }));
    }
  };

  const { handleSubmit, control } = useForm({
    defaultValues: {},
  });

  const debouncedChangeInputSearch = useCallback(
    debounce((searchQuery) => {
      if (steerCustomerSearchQuery.current === searchQuery) return;
      steerCustomerSearchQuery.current = searchQuery;
      dispatch(fetchSteerCustomers(searchQuery));
    }, 800),
    []
  );

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={onToggle}
        contentClassName={classes.Modal__Content}
        className={classes.Modal__Dialog}
        centered={true}
      >
        <div className={classes.Modal__Header}>
          <div>
            <AddCustomerIcon className={classes.Modal__CarIcon} />
            <div className={classes.Modal__Title}>Add Customer</div>
          </div>
          <CloseIcon className={classes.Modal__CloseIcon} onClick={onToggle} />
        </div>
        <hr className={classes.Modal_HorizontalLine} />
        <ModalBody className={classes.Modal__Body}>
          {customerError && (
            <Alert color="danger" className={alertClasses.DangerAlert}>
              {customerError}
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="customer"
              control={control}
              rules={{ required: true }}
              defaultValue={[]}
              render={({ onChange, value, name, error }) => (
                <SelectInput
                  label="Customer"
                  options={isLoading ? [] : steerNotAddedCustomers}
                  onInputChange={(customer) => {
                    onChange(customer);
                    setSelectedCustomer(customer);
                  }}
                  value={value}
                  name={name}
                  error={error}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  className="mt-27"
                  isSearchable
                  isClearable
                  onSearch={debouncedChangeInputSearch}
                  isLoading={isLoading}
                />
              )}
            />
          </form>
        </ModalBody>
        <ModalFooter className={classes.Modal__Footer}>
          <button className={classes.Modal__CancelBtn} onClick={onToggle}>
            Cancel
          </button>
          <button
            className={classes.Modal__SubmitBtn}
            onClick={handleSubmit(onSubmit)}
          >
            {isAddCustomersPending ? (
              <LoaderIcon className={classes.Modal__Loader} />
            ) : (
              "Add Customer"
            )}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
