import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import {
  getFromLocalStorage,
  setToLocalStorage,
  removeFromLocalStorage,
} from "../utils";

import {
  authenticateUser,
  setClientAuthorizationHeader,
  removeClientAuthorizationHeader,
  refreshUser,
} from "../services/api.service";

const loginUserThunk = createAsyncThunk("ui/login", async (data) => {
  const { accessToken, refreshToken, userName } = await authenticateUser(data);
  setClientAuthorizationHeader(accessToken);
  setToLocalStorage(`refreshToken`, refreshToken);
  setToLocalStorage(`accessToken`, accessToken);
  setToLocalStorage(`userName`, userName);
  return userName;
});

export const logoutUser = createAction("ui/logout", function prepare() {
  removeClientAuthorizationHeader();
  removeFromLocalStorage(`refreshToken`);
  removeFromLocalStorage(`accessToken`);
  removeFromLocalStorage(`selectedTenant`);
  removeFromLocalStorage(`userName`);
  removeFromLocalStorage(`selectedShop`);
  return {};
});

const refreshUserThunk = createAsyncThunk("ui/refresh", async (data) => {
  const { accessToken, refreshToken } = await refreshUser(data);
  setClientAuthorizationHeader(accessToken);
  setToLocalStorage(`refreshToken`, refreshToken);
  setToLocalStorage(`accessToken`, accessToken);
  return {};
});

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    userName: getFromLocalStorage("userName"),
    isAdmin: false,
    login: {
      isLoading: false,
      isError: false,
      isLoggedIn: false,
    },
    vehicles: {
      columns: {
        vehicle: {
          title: "Vehicle / Customer",
          order: 2, //order step is 2 for drag to reorder feature
          hidden: false,
          collapsed: false, //shown in expanded row on tablet, first 5 not hidden rows should be not collapsed
          isSortable: true, //support sorting table by this column
        },
        service: {
          title: "Miles Since Last Service",
          order: 4,
          hidden: false,
          collapsed: false,
          isSortable: true,
        },
        check: {
          title: "Check Engine Light",
          order: 6,
          hidden: false,
          collapsed: false,
          isSortable: true,
        },
        dtcs: {
          title: "DTC Codes",
          order: 8,
          hidden: false,
          collapsed: false,
          isSortable: true,
        },
        warnings: {
          title: "Warning Lights",
          order: 10,
          hidden: false,
          collapsed: false,
          isSortable: true,
        },
        others: {
          title: "Other Alerts",
          order: 12,
          hidden: false,
          collapsed: true,
          isSortable: true,
        },
        psi: {
          title: "Lowest PSI",
          order: 14,
          hidden: false,
          collapsed: true,
          isSortable: true,
          tooltip: "Lowest PSI value that is being reported",
        },
        shop: {
          title: "Shop Location",
          order: 16,
          hidden: false,
          collapsed: true,
          isSortable: false,
        },
      },
      filters: {
        checkEngine: {
          isActive: false,
          data: true,
        },
        warnings: {
          isActive: false,
          data: true,
        },
        others: {
          isActive: false,
          data: true,
        },
      },
    },
  },
  reducers: {
    setAdmin(state, action) {
      state.isAdmin = action.payload;
    },
    setIsLoggedIn(state, action) {
      state.login.isLoggedIn = action.payload;
    },
    updateVehiclesColumn(state, action) {
      const { field, update } = action.payload;

      for (const [key, val] of Object.entries(update)) {
        state.vehicles.columns[field][key] = val;
      }

      const sortedColumns = Object.entries(state.vehicles.columns).sort(
        (a, b) => a[1].order - b[1].order
      );

      let visibleColumnsCount = 0;
      sortedColumns.forEach(([field, data], i) => {
        if (!data.hidden) {
          state.vehicles.columns[field].collapsed = ++visibleColumnsCount > 5;
        } else {
          state.vehicles.columns[field].collapsed = false;
        }
        state.vehicles.columns[field].order = (i + 1) * 2;
      });
    },
    updateVehiclesFilters(state, action) {
      for (const [key, value] of Object.entries(action.payload)) {
        state.vehicles.filters[key] = value;
      }
    },
    setVehiclesFilters(state, action) {
      for (const key in state.vehicles.filters) {
        const filter = action.payload[key];
        if (filter) {
          state.vehicles.filters[key] = filter;
        } else {
          state.vehicles.filters[key].isActive = false;
        }
      }
    },
  },
  extraReducers: {
    [loginUserThunk.pending](state) {
      state.login.isLoading = true;
      state.login.isError = false;
      state.login.isLoggedIn = false;
    },
    [loginUserThunk.fulfilled](state, action) {
      state.login.isLoading = false;
      state.login.isError = false;
      state.login.isLoggedIn = true;
      state.userName = action.payload;
    },
    [loginUserThunk.rejected](state) {
      state.login.isLoading = false;
      state.login.isError = true;
      state.login.isLoggedIn = false;
    },
    [logoutUser](state) {
      // user will be redirected to /login
      state.login.isLoggedIn = false;
    },
    [refreshUserThunk.pending](state) {
      state.login.isError = false;
      state.login.isLoggedIn = true;
    },
    [refreshUserThunk.fulfilled](state) {
      state.login.isError = false;
      state.login.isLoggedIn = true;
    },
    [refreshUserThunk.rejected](state) {
      state.login.isError = true;
      state.login.isLoggedIn = false;
    },
  },
});

export { loginUserThunk as loginUser };
export { refreshUserThunk as refreshUser };

const { actions, reducer } = uiSlice;
export const {
  setAdmin,
  setIsLoggedIn,
  updateVehiclesColumn,
  updateVehiclesFilters,
  setVehiclesFilters,
} = actions;

export const getUserName = (state) => state.ui.userName;
export const getAdminStatus = (state) => state.ui.isAdmin;
export const getIsLoggedIn = (state) => state.ui.login.isLoggedIn;
export const getIsLoading = (state) => state.ui.login.isLoading;
export const getIsError = (state) => state.ui.login.isError;
export const getVehiclesColumns = (state) => state.ui.vehicles.columns;
export const getVehiclesFilters = (state) => state.ui.vehicles.filters;

export default reducer;
