import React, { useCallback, useEffect, useRef } from "react";
import { debounce } from "lodash";
import {
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import cn from "classnames";
import {
  CloseIcon,
  NewVehicleIcon,
  LoaderIcon,
  EditVehicleIcon,
} from "../../../../assets/icons/";
import "../../../../custom-modal.css";
import classes from "../../../../styles/modal.module.scss";
import formClasses from "../../../../styles/input.module.scss";
import alertClasses from "../../../../styles/alert.module.scss";
import {
  createCustomerVehicle,
  editCustomerVehicle,
  clearError,
  selectVehicle,
  fetchCustomerSteerVehicles,
} from "../../vehicles.slice";
import {
  getSelectedVehicle,
  getVehicleState,
  getSteerCustomerVehicles,
  isSteerCustomerVehiclesLoading,
  isVehicleLoading,
} from "../../vehicles.selectors";
import { getSelectedCustomer } from "../../../customers/customers.selectors";
import { getSelectedTenant } from "../../../tenants/tenants.selectors";
import {
  convertMilesToMeters,
  convertKilometersToMeters,
} from "../../../../utils";
import SelectInput from "../../../../components/ui/select-input/SelectInput";
import {
  getFilteredDevices,
  isShopDevicesLoading,
  getSelectedShopId,
} from "../../../shops/shops.selectors";
import { setDeviceFilter, fetchShopDevices } from "../../../shops/shops.slice";

export function VehicleModal({ toggle, isEdit }) {
  const selectedVehicle = useSelector(getSelectedVehicle);
  const selectedCustomer = useSelector(getSelectedCustomer);
  const selectedTenant = useSelector(getSelectedTenant);
  const vehicleState = useSelector(getVehicleState);
  const dispatch = useDispatch();
  const history = useHistory();

  const steerCustomerVehicles = useSelector(getSteerCustomerVehicles);
  const isVehiclesLoading = useSelector(isSteerCustomerVehiclesLoading);
  const shopDevices = useSelector(getFilteredDevices);
  const isDevicesLoading = useSelector(isShopDevicesLoading);
  const isAddVehiclePending = useSelector(isVehicleLoading);
  const selectedShopId = useSelector(getSelectedShopId);

  const steerVehicleSearchQuery = useRef("");

  useEffect(() => {
    if (vehicleState.isShownEditModal || vehicleState.isShownCreateModal) {
      dispatch(fetchShopDevices());
      dispatch(
        fetchCustomerSteerVehicles({
          steerCustomerId: selectedCustomer.steerCustomerId,
        })
      );
    }
  }, [vehicleState.isShownEditModal, vehicleState.isShownCreateModal]);

  const isMeasurementSystemImperial =
    selectedCustomer && selectedCustomer.measurementSystem === "imperial";

  const onSubmit = async (data) => {
    dispatch(clearError());

    data = {
      ...data,
      customerId: selectedCustomer.id,
      deviceId: data.deviceId.deviceSerialNumber,
      tenantId: selectedTenant.id,
      ...(!isEdit && { steerVehicleId: data.vehicle.id }),
    };

    if (isEdit) {
      data.id = selectedVehicle.id;

      if (data.deviceId !== selectedVehicle.overview.data.serialNumber) {
        data.serialNumber = data.deviceId;
      }

      if (
        Number(data.odometer) !== selectedVehicle.overview.data.odometer.value
      ) {
        data.odometer = isMeasurementSystemImperial
          ? convertMilesToMeters(Number(data.odometer))
          : convertKilometersToMeters(Number(data.odometer));
      } else {
        delete data.odometer;
      }
      await dispatch(editCustomerVehicle(data));
      dispatch(selectVehicle(null));
      dispatch(selectVehicle(selectedVehicle.id));
    } else {
      dispatch(selectVehicle(null));
      history.push(`/${selectedShopId}/customer/${selectedCustomer.id}`);
      await dispatch(createCustomerVehicle(data));
    }
    // when reactstrap modal is removed, its class and style remain and prevents page scroll
    document.body.classList.remove("modal-open");
    document.body.style = "";
  };

  const onToggle = () => {
    toggle();
    dispatch(clearError());
    dispatch(setDeviceFilter(""));
  };

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: isEdit
      ? {
          deviceId: {
            deviceSerialNumber: selectedVehicle.overview.data.serialNumber,
          },
          odometer:
            selectedVehicle.overview.data.odometer.value &&
            selectedVehicle.overview.data.odometer.value.toLocaleString([
              "en-US",
            ]),
        }
      : {},
  });

  const debounceChangeVehicleSearch = useCallback(
    debounce((searchQuery) => {
      if (steerVehicleSearchQuery.current === searchQuery || !selectedCustomer)
        return;
      steerVehicleSearchQuery.current = searchQuery;
      dispatch(
        fetchCustomerSteerVehicles({
          steerCustomerId: selectedCustomer.steerCustomerId,
          searchQuery,
        })
      );
    }, 800),
    [selectedCustomer]
  );

  const debounceChangeDeviceIdSearch = useCallback(
    debounce((searchQuery) => {
      dispatch(setDeviceFilter(searchQuery));
    }, 800),
    []
  );

  const handleInputChange = (value) => {
    const numericValue = value.replace(/,/g, "");
    if (!isNaN(numericValue)) {
      setValue("odometer", numericValue);
    }
  };

  return (
    <div>
      <Modal
        isOpen={
          isEdit
            ? vehicleState.isShownEditModal
            : vehicleState.isShownCreateModal
        }
        toggle={onToggle}
        contentClassName={classes.Modal__Content}
        className={classes.Modal__Dialog}
        centered={true}
      >
        <div className={classes.Modal__Header}>
          <div>
            {isEdit ? (
              <EditVehicleIcon className={classes.Modal__CarIcon} />
            ) : (
              <NewVehicleIcon className={classes.Modal__CarIcon} />
            )}
            <div className={classes.Modal__Title}>
              {" "}
              {isEdit ? "Edit Vehicle Info" : "Add Vehicle"}
            </div>
          </div>
          <CloseIcon className={classes.Modal__CloseIcon} onClick={onToggle} />
        </div>
        <hr className={classes.Modal_HorizontalLine} />
        <ModalBody className={classes.Modal__Body}>
          {vehicleState.error && (
            <Alert color="danger" className={alertClasses.DangerAlert}>
              {vehicleState.error}
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            {!isEdit && (
              <FormGroup>
                <Controller
                  control={control}
                  name="vehicle"
                  rules={{ required: true }}
                  defaultValue={[]}
                  render={({ onChange, value, name, error }) => (
                    <SelectInput
                      label="Vehicle"
                      options={isVehiclesLoading ? [] : steerCustomerVehicles}
                      onInputChange={(vehicle) => {
                        onChange(vehicle);
                      }}
                      value={value}
                      name={name}
                      error={error}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      className="mt-27"
                      isSearchable
                      isClearable
                      onSearch={debounceChangeVehicleSearch}
                      isLoading={isVehiclesLoading}
                    />
                  )}
                />
              </FormGroup>
            )}
            <FormGroup
              className={cn(formClasses.deviceIdWrapper, {
                [formClasses.deviceIdEdited]: isEdit,
              })}
            >
              <Controller
                control={control}
                name="deviceId"
                rules={{ required: true }}
                defaultValue={[]}
                render={({ onChange, value, name, error }) => (
                  <SelectInput
                    label="Device ID"
                    options={isDevicesLoading ? [] : shopDevices}
                    onInputChange={(deviceId) => {
                      onChange(deviceId);
                      dispatch(setDeviceFilter(""));
                    }}
                    value={value}
                    name={name}
                    error={error}
                    getOptionValue={(option) => option.deviceSerialNumber}
                    getOptionLabel={(option) => option.deviceSerialNumber}
                    className="mt-27"
                    isClearable={!isEdit}
                    onSearch={debounceChangeDeviceIdSearch}
                    isLoading={isDevicesLoading}
                  />
                )}
              />
            </FormGroup>
            {isEdit && selectedVehicle.isOdometerChangeAvailable && (
              <FormGroup>
                <Label for="odometer" className={formClasses.InputLabel}>
                  Odometer
                  <span className={formClasses.InputLabel__Optional}>
                    {" "}
                    ({isMeasurementSystemImperial ? "miles" : "kilometers"})
                  </span>
                </Label>
                <Controller
                  name="odometer"
                  control={control}
                  render={({ value, error, onChange, name }) => (
                    <Input
                      name={name}
                      onChange={(e) => {
                        onChange(e.target.value);
                        handleInputChange(e.target.value);
                      }}
                      className={formClasses.Input}
                      id="odometer"
                      error={error}
                      value={
                        value && !isNaN(value)
                          ? Number(value).toLocaleString()
                          : value || ""
                      }
                    />
                  )}
                />
              </FormGroup>
            )}
          </form>
        </ModalBody>
        <ModalFooter className={classes.Modal__Footer}>
          <button className={classes.Modal__CancelBtn} onClick={onToggle}>
            Cancel
          </button>
          <button
            className={classes.Modal__SubmitBtn}
            onClick={handleSubmit(onSubmit)}
          >
            {isAddVehiclePending ? (
              <LoaderIcon className={classes.Modal__Loader} />
            ) : isEdit ? (
              "Save"
            ) : (
              "Add Vehicle"
            )}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
