import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { getRoutePrefix } from "../../services/env.service";

import CustomersTable from "./customer-table/CustomersTable";
import CustomerCounter from "./customer-counter/CustomerCounter";
import CustomerSearch from "./customer-search/CustomerSearch";
import { CustomerModal } from "../customer-details/components/customer-modal/CustomerModal";
import ShopCounter from "./shop-counter/ShopCounter";
import {
  getCustomers,
  getFilteredCustomers,
} from "../../scenes/customers/customers.selectors";
import { getAdminStatus } from "../../App/ui.slice";
import classes from "./Customers.module.scss";
import styles from "../shops/HeaderShopToggle/components/RequestResult/RequestResult.module.scss";
import { CustomerGroupIcon, NoResultIcon } from "../../assets/icons";
import RequestResult from "../shops/HeaderShopToggle/components/RequestResult/RequestResult";
import { TABLE_COLUMN_ID, ALL_LOCATIONS } from "../../constants";

const CUSTOMERS_SORT_ORDER = [
  TABLE_COLUMN_ID.VEHICLES_ENGINE_LIGHTS,
  TABLE_COLUMN_ID.VEHICLES_WARNINGS,
  TABLE_COLUMN_ID.VEHICLE_DTC_CODES,
  TABLE_COLUMN_ID.VEHICLE_OTHER_ALERTS,
  TABLE_COLUMN_ID.VEHICLES,
];

export default function CustomerSelectionPage() {
  const [showModal, setShowModal] = useState(false);
  const customers = useSelector(getCustomers);
  const filteredCustomers = useSelector(getFilteredCustomers);
  const hasCustomers = customers && customers.length > 0;
  const isSearchResultEmpty =
    filteredCustomers && filteredCustomers.length === 0;
  const isAdmin = useSelector(getAdminStatus);
  const [sortedCustomers, setSortedCustomers] = useState(null);
  const selectedShopId = getRoutePrefix();
  const isAllLocation = selectedShopId === ALL_LOCATIONS.id;

  useEffect(() => {
    if (!filteredCustomers) return;

    const sorted = [...filteredCustomers].sort((a, b) => {
      for (const field of CUSTOMERS_SORT_ORDER) {
        if (a[field] === b[field]) continue;
        if (a[field] === "N/A") return 1;
        return (b[field].length > a[field].length && 1) || -1;
      }
    });

    setSortedCustomers(sorted);
  }, [filteredCustomers]);

  return (
    <div
      className={cn(classes.Container, { [classes.noIndents]: !hasCustomers })}
    >
      {hasCustomers && (
        <>
          <div className={classes.HeaderContainer}>
            <div className={classes.Title}>Customers</div>
            <CustomerSearch />
            <CustomerCounter className={classes.CounterContainer} />
            {isAllLocation && (
              <>
                <div className={classes.divider}></div>
                <ShopCounter className={classes.CounterContainer} />
              </>
            )}
          </div>

          {isSearchResultEmpty ? (
            <RequestResult
              wrapperClassName={styles.noSearchDataCustomersWrapper}
              className={styles.noSearchData}
              image={<NoResultIcon width={208} height={208} />}
              title="No results found"
              message={
                "We couldn't find anything matching your search.\nPlease try again."
              }
            />
          ) : (
            sortedCustomers && (
              <CustomersTable
                customers={sortedCustomers}
                isAllLocation={isAllLocation}
              />
            )
          )}
        </>
      )}
      {!hasCustomers && (
        <EmptyCustomers onClick={() => setShowModal(!showModal)} />
      )}
      <CustomerModal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
      />
    </div>
  );
}

function AddCustomerButton({ onClick, isEmptyCustomers }) {
  return (
    <button
      onClick={onClick}
      className={cn([classes.AddCustomer, classes.AddCustomer__button], {
        [classes.AddCustomer__buttonEmpty]: isEmptyCustomers,
      })}
    ></button>
  );
}

function EmptyCustomers({ onClick }) {
  return (
    <div className={classes.EmptyCustomers}>
      <CustomerGroupIcon />
      <div className={classes.Title}>It's a bit empty here...</div>
      <div className={classes.Message}>No customers have been added yet.</div>
      <AddCustomerButton onClick={onClick} isEmptyCustomers />
    </div>
  );
}
