import React, { useState } from "react";
import cn from "classnames";
import styles from "./InfoTooltip.module.scss";
import { InfoIcon } from "../../assets/icons";
import Popper from "../../components/popper/Popper";

export default function InfoTooltip({ children, text, className }) {
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div
      className={styles.info}
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
      onClick={() => setTooltipOpen(true)}
    >
      <Popper
        toggler={<InfoIcon className={styles.icon} />}
        placement="top"
        isOpen={isTooltipOpen}
        onOutsideClick={() => setTooltipOpen(false)}
        modifiers={[
          {
            name: "flip",
            options: {
              fallbackPlacements: ["top-start", "bottom", "bottom-start"],
            },
          },
        ]}
      >
        <div className={cn(styles.tooltip, className)}>{children || text}</div>
      </Popper>
    </div>
  );
}
