import React, { useMemo } from "react";

import styles from "./VehicleDTC.module.scss";

import { setShopIdHeader } from "../../services/api.service";

import DtcCodes from "../../scenes/customer-details/components/dtc-codes/DtcCodes";

export default function VehicleDTC({ vehicleId, deviceId, shopId }) {
  useMemo(() => setShopIdHeader(shopId), []);
  return (
    <div className={styles.dtc}>
      <DtcCodes vehicleId={vehicleId} deviceId={deviceId} />
    </div>
  );
}
