import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.scss";

import { selectShop } from "../shops/shops.slice";
import { setShopIdHeader } from "../../services/api.service";
import {
  selectTenant,
  setTenantsShown,
  setIsFirstTenantsRender,
} from "../tenants/tenants.slice";
import { setCustomerFilter } from "../customers/customers.slice";
import { getCustomerFilter } from "../customers/customers.selectors";
import { showDeviceModal } from "../customer-details/vehicles.slice";
import {
  SteermaticsLogoMenuMobile,
  ToggleMenuTabletIcon,
  MenuDeviceIcon,
} from "../../assets/icons";
import HeaderShopToggle from "../shops/HeaderShopToggle";
import { handleRouting } from "../../services/routing.service";
import Search from "./Search";
import DeviceModal from "./DeviceModal";

export default function Header({ isMenuVisible, onMenuToggle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isAdminPage = location.pathname === "/admin";
  const customerFilterValue = useSelector(getCustomerFilter);
  const [isSearchOpen, setSearchOpen] = useState(false);

  function handleShopSelect(shop) {
    dispatch(selectShop(shop));
    setShopIdHeader(shop.id);
    handleRouting(shop.id, history, location.pathname);
    customerFilterValue && dispatch(setCustomerFilter(""));
  }

  function handleTenantSelect(tenant) {
    dispatch(selectTenant(tenant));
    dispatch(setTenantsShown(false));
    dispatch(setIsFirstTenantsRender(false));
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={cn(styles.logoContainerMobile, {
          [styles.hidden]: isSearchOpen,
        })}
      >
        <SteermaticsLogoMenuMobile className={styles.logo} />
        <ToggleMenuTabletIcon
          className={cn(styles.button, { [styles.active]: isMenuVisible })}
          onClick={onMenuToggle}
        />
      </div>
      <div className={styles.searchContainer}>
        <Search
          isOpen={isSearchOpen}
          onToggle={() => setSearchOpen(!isSearchOpen)}
        />
      </div>
      <div
        className={cn(styles.buttonInstallDevice, {
          [styles.hidden]: isSearchOpen,
        })}
        onClick={() => dispatch(showDeviceModal(true))}
      >
        <MenuDeviceIcon className={styles.icon} />
        <div className={styles.title}>Install Device</div>
        <DeviceModal />
      </div>
      {!isAdminPage && (
        <HeaderShopToggle
          isHidden={isSearchOpen}
          isBackgroundHover
          onShopClick={(shop) => handleShopSelect(shop)}
          onTenantClick={(tenant) => handleTenantSelect(tenant)}
        />
      )}
      <div
        className={cn(styles.menuUnderlay, { [styles.visible]: isMenuVisible })}
        onClick={onMenuToggle}
      />
    </div>
  );
}
