import React from "react";

import styles from "./VehiclePSI.module.scss";

import { CheckRoundIcon, AttentionIcon } from "../../assets/icons";

const TITLES = {
  tirePressureFrontLeft: "Front Left",
  tirePressureFrontRight: "Front Right",
  tirePressureRearLeft: "Rear Left",
  tirePressureRearRight: "Rear Right",
};

export default function VehiclePSI({ tirePressures }) {
  return (
    <div className={styles.psi}>
      <div className={styles.header}>
        <span className={styles.title}>PSI</span>
      </div>
      {Object.entries(tirePressures).map(([id, value]) => (
        <div key={id} className={styles.pressure}>
          {value < 30 ? (
            <AttentionIcon className={styles.icon} />
          ) : (
            <CheckRoundIcon className={styles.icon} />
          )}
          <div className={styles.title}>{TITLES[id]}:</div>
          <div className={styles.value}>{value} PSI</div>
        </div>
      ))}
    </div>
  );
}
