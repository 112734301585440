import React from "react";
import cn from "classnames";

import styles from "./MobileRow.module.scss";
import Typography from "../../../typography/Typography";
import VehiclesCell from "../../../../scenes/customers/customer-table/vehicles-cell/VehiclesCell";
import ShopCell from "../../../../scenes/customers/customer-table/shop-cell/ShopCell";

const MobileRow = ({ className, row, data, isAllLocation }) => {
  return (
    <div
      className={cn(styles.mobileRow, className, {
        [styles.rowMargin]: row?.row.index !== data.length - 1,
      })}
    >
      {isAllLocation && (
        <div className={styles.row}>
          <Typography color="tertiary" fontSize="md">
            Shop Location
          </Typography>
          <ShopCell row={row} value={row.row?.values?.shopLocation} />
        </div>
      )}
      <div className={styles.row}>
        <Typography color="tertiary" fontSize="md">
          Vehicles
        </Typography>
        <VehiclesCell row={row} value={row.row?.values?.vehicles} />
      </div>
      <div className={styles.row}>
        <Typography color="tertiary" fontSize="md">
          Check Engine Light
        </Typography>
        <VehiclesCell row={row} value={row.row?.values?.vehiclesEngineLights} />
      </div>
      <div className={styles.row}>
        <Typography color="tertiary" fontSize="md">
          Warning Lights
        </Typography>
        <VehiclesCell
          row={row}
          value={row.row?.values?.vehiclesWarnings}
          isWarningsCol
        />
      </div>
      <div className={styles.row}>
        <Typography color="tertiary" fontSize="md">
          DTC Codes
        </Typography>
        <VehiclesCell row={row} value={row.row?.values?.vehiclesDTCCodes} />
      </div>
      <div className={styles.row}>
        <Typography color="tertiary" fontSize="md">
          Other Alerts
        </Typography>
        <VehiclesCell
          row={row}
          value={row.row?.values?.vehiclesOtherAlerts}
          isWarningsCol
        />
      </div>
    </div>
  );
};

export default MobileRow;
