import React, { useState } from "react";
import cn from "classnames";
import * as styles from "./Layout.module.scss";
import Header from "../scenes/header/Header";
import Menu from "../components/menu/Menu";

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const handleMenuToggle = () => setIsMenuOpen((x) => !x);
  const handleMenuToggleMobile = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
    setIsMenuVisible((x) => !x);
    setIsMenuOpen(true);
  };
  const handleMenuItemClick = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
    setIsMenuVisible(false);
  };

  return (
    <div className={cn(styles.layout, { [styles.menuOpen]: isMenuOpen })}>
      <Header
        isMenuVisible={isMenuVisible}
        onMenuToggle={handleMenuToggleMobile}
      />
      <Menu
        isOpen={isMenuOpen}
        isVisible={isMenuVisible}
        onToggle={handleMenuToggle}
        onMenuToggle={handleMenuToggleMobile}
        onItemClick={handleMenuItemClick}
      />
      <div>{children}</div>
    </div>
  );
};

export default Layout;
