import React from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import classes from "../customer-counter/CustomerCounter.module.scss";
import { getShops } from "../../shops/shops.selectors";

export default function ShopCounter({ className }) {
  const shops = useSelector(getShops);
  return (
    <div className={cn(className, [classes["CustomerCounter"]])}>
      Shop Locations:{" "}
      <span
        className={cn([classes["CustomerCounter__totalValue"], "align-middle"])}
      >
        {shops.length}
      </span>
    </div>
  );
}
