/* eslint-disable no-unused-vars */
import cn from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useCallback } from "react";
import Select, { components } from "react-select";

import Scrollbar from "../../scroll-bar";
import { CloseIcon, NoOptionsIcon } from "../../../assets/icons";
import { useScreenSize } from "../../../utility/hooks";
import { PROP_TYPES_TEMPLATES } from "../../../commonPropTypes";
import InputError from "../../input-error/InputError";
import Loader from "../../loader/Loader";
import Label from "../../label/Label";
import styles from "./SelectInput.module.scss";
import { customStyles } from "./SelectInputStyles";

const SCROLLBAR_MAX_HEIGHT = 335;

const SelectInput = ({
  className,
  labelClassName,
  defaultValue,
  value,
  error,
  onInputChange,
  onFocus = () => {},
  onBlur = () => {},
  onClear,
  getOptionLabel,
  getOptionValue,
  placeholder = "",
  disabled,
  options,
  label,
  isMulti,
  additionalLabel,
  isSearchable = false,
  isClearable,
  formatOptionLabel,
  renderRow,
  onSearch,
  name,
  menuPlacement = "bottom",
  isLoading,
  inputRef,
  usePortal,
}) => {
  const errorMessage = error?.message;
  const { isMobile } = useScreenSize();

  const handleChange = (newValue, triggeredAction) => {
    if (onClear && triggeredAction?.action === "clear") {
      onClear();
    }

    onInputChange && onInputChange(isMulti ? newValue || [] : newValue);
  };

  const handleFocus = () => {
    onFocus();
  };

  const handleBlur = () => {
    // trigger onblur change action so react hook form would throw error (reputation review case)
    if (onClear) {
      handleChange(value);
    }
    onBlur();
  };

  const renderNoOptions = () => (
    <div className="flex flex-col justify-center items-center pt-15 pb-10">
      <NoOptionsIcon />
      <span className={styles.SelectInput__NoOptionsLabel}>No options</span>
    </div>
  );

  const renderLoadingMsg = () => (
    <div className="flex flex-col justify-center items-center pt-15 pb-10">
      <Loader />
    </div>
  );

  // const LoadingIndicator = () => (<Loader />);
  const MenuList = (props) => {
    return (
      <Scrollbar height={SCROLLBAR_MAX_HEIGHT}>{props.children}</Scrollbar>
    );
  };

  const Option = ({ innerProps, ...rest }) => (
    <div {...innerProps} className={styles.option}>
      {renderRow(rest)}
    </div>
  );

  const ValueContainer = useCallback(
    ({ children, ...props }) => (
      <components.ValueContainer {...props}>
        <div
          className={cn("flex items-center w-full", {
            "justify-between": errorMessage,
          })}
        >
          <div className="flex wrap items-center">{children}</div>
          {!disabled && errorMessage && (
            <InputError
              isOpen={errorMessage}
              errorMessage={errorMessage}
              className={styles.error}
            />
          )}
        </div>
      </components.ValueContainer>
    ),
    [errorMessage, disabled]
  );

  const ClearIndicator = useCallback(
    ({ children, ...props }) => (
      <components.ClearIndicator {...props}>
        <CloseIcon width={12} height={12} className={styles.clearIndicator} />
      </components.ClearIndicator>
    ),
    [errorMessage, disabled]
  );

  const customComponents = {
    ...(options && options.length > 7 && isMobile && { MenuList }),
    ...(renderRow && { Option }),
    ...{ ValueContainer, ClearIndicator },
    LoadingIndicator: errorMessage ? null : components.LoadingIndicator,
  };

  // todo check in docs how pass ref for select
  return (
    <div className={cn(className, "w-full")} name={name}>
      <Label
        label={label}
        additionalLabel={additionalLabel}
        className={labelClassName}
        disabled={disabled}
      />
      <Select
        menuPortalTarget={usePortal && document.body}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        ref={inputRef}
        isError={!disabled && errorMessage}
        captureMenuScroll={false}
        components={customComponents}
        value={value}
        onChange={handleChange}
        onInputChange={onSearch}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        styles={customStyles}
        options={options}
        isMulti={isMulti}
        menuPlacement={menuPlacement}
        defaultValue={defaultValue}
        isDisabled={disabled}
        isClearable={isClearable}
        isSearchable={isSearchable || isMulti}
        noOptionsMessage={renderNoOptions}
        isLoading={isLoading}
        loadingMessage={renderLoadingMsg}
        closeMenuOnSelect={!isMulti}
        formatOptionLabel={formatOptionLabel}
      />
    </div>
  );
};

SelectInput.propTypes = {
  label: PROP_TYPES_TEMPLATES.LABEL,
  additionalLabel: PROP_TYPES_TEMPLATES.LABEL,
  name: PropTypes.string,
  menuPlacement: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  options: PropTypes.array,
  onInputChange: PropTypes.func,
  onClear: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  onSearch: PropTypes.func,
  formatOptionLabel: PropTypes.func,
  renderRow: PropTypes.func,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  usePortal: PropTypes.bool,
  inputRef: PropTypes.element,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
};

export default SelectInput;
