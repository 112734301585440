import React, { useEffect, useCallback, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import cn from "classnames";

import styles from "./CustomizeColumns.module.scss";

import { getVehiclesColumns, updateVehiclesColumn } from "../../App/ui.slice";

import Checkbox from "../../components/ui/checkbox/Checkbox";

import {
  CustomizeColumnsIcon,
  ArrowDownIcon,
  DragIcon,
} from "../../assets/icons";

export default function CustomizeColumns({ isAllLocations }) {
  const dispatch = useDispatch();
  const columns = useSelector(getVehiclesColumns);
  const [isOpen, setIsOpen] = useState(false);
  const refWrapper = useRef(null);

  const handleToggle = () => setIsOpen((x) => !x);

  useEffect(() => {
    if (!isOpen || !refWrapper.current) return;

    const handleOutsideClick = (e) => {
      if (!refWrapper.current.contains(e.target)) {
        handleToggle();
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [refWrapper.current, isOpen]);

  const handleToggleColumn = ({ field, isSelected }) => {
    dispatch(updateVehiclesColumn({ field, update: { hidden: !isSelected } }));
  };

  const handleDrag = ({ item, field }) => {
    item.classList.add(styles.dragging);

    const itemHeight = item.offsetHeight;
    const initialOrder = Number(item.style.order);
    let order = initialOrder;
    let orderAdjustment = 0;
    let offset = 0;
    let lastTouchY = 0;

    const processDrag = (e) => {
      if (e.touches?.length) {
        if (lastTouchY === 0) lastTouchY = e.touches[0].screenY;
        offset += e.touches[0].screenY - lastTouchY;
        lastTouchY = e.touches[0].screenY;
      } else {
        offset += e.movementY;
      }

      if (Math.abs(offset) > itemHeight) {
        const orderShift = Math.round(offset / itemHeight) * 2;

        order = Math.min(
          Math.max(2, order + orderShift),
          Object.keys(columns).length * 2
        );

        if (order === initialOrder) {
          orderAdjustment = 0;
        } else if (order > initialOrder) {
          orderAdjustment = +1;
        } else {
          orderAdjustment = -1;
        }

        offset = offset % itemHeight;
      }

      item.style.top = `${offset}px`;
      item.style.order = order + orderAdjustment;
    };

    const endDrag = () => {
      window.removeEventListener("mousemove", processDrag);
      window.removeEventListener("touchmove", processDrag);
      item.classList.remove(styles.dragging);
      item.style.top = `0px`;
      dispatch(
        updateVehiclesColumn({
          field,
          update: { order: order + orderAdjustment },
        })
      );
    };

    window.addEventListener("mousemove", processDrag);
    window.addEventListener("mouseup", endDrag, { once: true });
    window.addEventListener("touchmove", processDrag);
    window.addEventListener("touchend", endDrag, { once: true });
  };

  return (
    <div className={styles.wrapper} ref={refWrapper}>
      <div
        className={cn(styles.button, { [styles.active]: isOpen })}
        onClick={handleToggle}
      >
        <CustomizeColumnsIcon />
        Customize Columns
        <ArrowDownIcon />
      </div>
      <div className={cn(styles.content, { [styles.hidden]: !isOpen })}>
        <div className={styles.header}>
          <div>Show/Hide Columns</div>
          <div>(Drag to reorder)</div>
        </div>
        <div className={styles.items}>
          {Object.entries(columns).map(([field, data]) => {
            if (field === "shop" && !isAllLocations) return null;
            return (
              <div
                key={field}
                className={styles.item}
                style={{ order: data.order }}
              >
                <Checkbox
                  isChecked={!data.hidden}
                  title={data.title}
                  onChange={(isSelected) => {
                    handleToggleColumn({ field, isSelected });
                  }}
                />
                <DragIcon
                  onMouseDown={(e) =>
                    handleDrag({ item: e.currentTarget.parentNode, field })
                  }
                  onTouchStart={(e) =>
                    handleDrag({ item: e.currentTarget.parentNode, field })
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
