import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./Collapse.module.scss";
import Switch from "../ui/switch/Switch";
import { PROP_TYPES_TEMPLATES } from "../../commonPropTypes";
import { ArrowDownIcon } from "../../assets/icons";

const Collapse = ({
  isOpened,
  disabled,
  content,
  children,
  onCollapse,
  useToggle,
  ToggleComponent,
  className,
  togglerClassName,
  collapseArrowClassName,
  collapseArrowOpenedClassName,
  contentContainerClassName,
  enableHover = false,
}) => (
  <div className={cn(styles.collapse, className)}>
    <div
      className={cn(
        styles.toggler,
        "flex items-center justify-between px-24",
        togglerClassName,
        {
          pointer: !(disabled || useToggle),
          [styles.enableHover]: enableHover,
        }
      )}
      onClick={(e) => {
        if (!disabled) {
          onCollapse(e);
        }
      }}
    >
      <div
        className={cn(contentContainerClassName, {
          [styles.disabled]: disabled,
        })}
      >
        {content}
      </div>

      {useToggle && <Switch onChange={onCollapse} isChecked={isOpened} />}
      {!useToggle &&
        (ToggleComponent ? (
          ToggleComponent
        ) : (
          <div className="relative flex items-center">
            <ArrowDownIcon
              width="20"
              height="24"
              className={cn(styles.arrow, collapseArrowClassName, {
                [styles.arrowRotated]: isOpened,
                [collapseArrowOpenedClassName]: isOpened,
              })}
            />
          </div>
        ))}
    </div>
    <div
      className={cn({ [styles.content]: !isOpened })}
      style={{
        maxHeight: isOpened ? "100%" : 0,
      }}
    >
      {children}
    </div>
  </div>
);

Collapse.propTypes = {
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  isOpened: PropTypes.bool,
  useToggle: PropTypes.bool,
  enableHover: PropTypes.bool,
  onCollapse: PropTypes.func.isRequired,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  togglerClassName: PROP_TYPES_TEMPLATES.CLASS_NAME,
  collapseArrowClassName: PROP_TYPES_TEMPLATES.CLASS_NAME,
  contentContainerClassName: PROP_TYPES_TEMPLATES.CLASS_NAME,
  children: PROP_TYPES_TEMPLATES.CHILDREN,
};

export default Collapse;
