import React from "react";

import styles from "./VehicleWarnings.module.scss";

import WarningItem from "../../scenes/customer-details/components/warnings/WarningItem";

export default function VehicleWarnings({
  warnings,
  title = "Warning Lights",
}) {
  return (
    <div className={styles.warnings}>
      <div className={styles.header}>
        <span className={styles.title}>{title} </span>
        <span className={styles.count}>({warnings.length})</span>
      </div>
      {warnings.map((warning) => {
        return (
          <div key={warning.id}>
            <WarningItem type={warning.id} text={warning.text} isPopupWarning />
          </div>
        );
      })}
    </div>
  );
}
