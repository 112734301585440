import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchAllShops,
  setShopIdHeader,
  fetchShopDevices as fetchShopDevicesRequest,
  fetchTenantShops as fetchTenantShopsRequest,
} from "../../services/api.service";
import { keyBy } from "lodash";
import { setToLocalStorage } from "../../utils";
import { ALL_LOCATIONS } from "../../constants";

const fetchShopsThunk = createAsyncThunk(
  "shops/fetchAll",
  async ({ URLShopId } = {}) => {
    const { shops } = await fetchAllShops();
    return { shops, URLShopId };
  }
);

const fetchShopDevicesThunk = createAsyncThunk(
  "customerVehicles/devices",
  fetchShopDevicesRequest
);

const fetchTenantShopsThunk = createAsyncThunk(
  "shops/fetchAllTenantShops",
  async ({ URLShopId, selectedTenantId } = {}) => {
    const { shops } = await fetchTenantShopsRequest(selectedTenantId);
    return { shops, URLShopId };
  }
);

const shopsSlice = createSlice({
  name: "shops",
  initialState: {
    byId: null,
    isLoading: false,
    hasError: false,
    selectedShop: null,
    shopDevices: null,
    isShopDevicesLoading: false,
  },
  reducers: {
    selectShop(state, action) {
      state.selectedShop = action.payload;
      setToLocalStorage("selectedShop", action.payload.id);
    },
    setShopFilter(state, action) {
      state.shopFilter = action.payload;
    },
    setDeviceFilter(state, action) {
      state.deviceFilter = action.payload;
    },
  },
  extraReducers: {
    [fetchShopsThunk.pending](state) {
      state.byId = null;
      state.isLoading = true;
      state.hasError = false;
    },
    [fetchShopsThunk.fulfilled](state, action) {
      state.isLoading = false;
      state.hasError = false;
      state.byId = keyBy(action.payload.shops, "id");

      const { shops, URLShopId } = action.payload;
      const URLShop = URLShopId && shops.find((shop) => shop.id === URLShopId);

      state.selectedShop =
        URLShop || (shops.length > 1 ? ALL_LOCATIONS : shops[0]);

      setShopIdHeader(state.selectedShop.id);
      setToLocalStorage("selectedShop", state.selectedShop.id);
    },
    [fetchShopsThunk.rejected](state) {
      state.byId = null;
      state.isLoading = false;
      state.hasError = true;
    },
    [fetchShopDevicesThunk.pending](state) {
      state.shopDevices = null;
      state.isShopDevicesLoading = true;
    },
    [fetchShopDevicesThunk.fulfilled](state, action) {
      state.shopDevices = action.payload;
      state.isShopDevicesLoading = false;
    },
    [fetchShopDevicesThunk.rejected](state) {
      state.shopDevices = null;
      state.isShopDevicesLoading = false;
    },
    [fetchTenantShopsThunk.pending](state) {
      state.byId = null;
      state.isLoading = true;
      state.hasError = false;
      state.selectedShop = null;
    },
    [fetchTenantShopsThunk.fulfilled](state, action) {
      state.isLoading = false;
      state.hasError = false;
      state.byId = keyBy(action.payload.shops, "id");

      const { shops, URLShopId } = action.payload;
      const URLShop = URLShopId && shops.find((shop) => shop.id === URLShopId);
      state.selectedShop =
        URLShop || (shops.length > 1 ? ALL_LOCATIONS : shops[0]);
      setShopIdHeader(state.selectedShop.id);
      setToLocalStorage("selectedShop", state.selectedShop.id);
    },
    [fetchTenantShopsThunk.rejected](state) {
      state.byId = null;
      state.isLoading = false;
      state.hasError = true;
    },
  },
});

export {
  fetchShopsThunk as fetchShops,
  fetchTenantShopsThunk as fetchTenantShops,
};
export { fetchShopDevicesThunk as fetchShopDevices };

const { actions, reducer } = shopsSlice;

export const { selectShop, setShopFilter, setDeviceFilter } = actions;
export default reducer;
