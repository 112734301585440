import React from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import styles from "./CustomerCell.module.scss";
import Typography from "../../../../components/typography/Typography";
import { BusinessProfileIcon, AvatarIcon } from "../../../../assets/icons";
import { selectCustomer } from "../../customers.slice";
import { setShopIdHeader } from "../../../../services/api.service";
import { getSelectedShopId } from "../../../shops/shops.selectors";
import { ALL_LOCATIONS } from "../../../../constants";

const CustomerCell = ({ row }) => {
  const { value } = row.cell;
  const { isBusinessAccount, id: customerId, shopId } = row.cell.row.original;
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedShopId = useSelector(getSelectedShopId);

  function handleOnClick() {
    dispatch(selectCustomer(null));
    setShopIdHeader(shopId);
    if (selectedShopId === ALL_LOCATIONS.id) {
      history.push(`/tenant/customer/${customerId}`);
    } else {
      history.push(`/${shopId}/customer/${customerId}`);
    }
  }

  return (
    <div className={styles.customer}>
      <div className={cn(styles.avatar, "mr-15")}>
        {isBusinessAccount ? <BusinessProfileIcon /> : <AvatarIcon />}
      </div>
      <div onClick={() => handleOnClick()} className={styles.nameWrapper}>
        <Typography
          tag="div"
          fontSize="md"
          fontWeight="bold"
          color="primary"
          ellipsis
          className={styles.customerName}
        >
          {value ? value : "-"}
        </Typography>
      </div>
    </div>
  );
};

export default CustomerCell;
