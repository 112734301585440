import React, { useEffect, useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import cn from "classnames";

import { ALL_LOCATIONS } from "../../constants";

import { joinShopAddress } from "../../utils";

import SearchField from "../shops/HeaderShopToggle/components/SearchField/SearchField";
import EmptySearch from "../shops/HeaderShopToggle/components/RequestResult/RequestResult";
import Loader from "../../components/loader/Loader";

import {
  NoResultIcon2,
  BusinessProfileIcon,
  AvatarIcon,
  SearcherIcon,
} from "../../assets/icons";

import { getSelectedShopId, getShopsById } from "../shops/shops.selectors";

import { searchCustomers } from "../customers/customers.slice";
import {
  getCustomersById,
  getCustomersSearchLoading,
  getCustomersSearchResult,
} from "../customers/customers.selectors";

import styles from "./Header.module.scss";

export default function Search({ isOpen, onToggle }) {
  const dispatch = useDispatch();

  const customersById = useSelector(getCustomersById);

  const isCustomersSearchLoading = useSelector(getCustomersSearchLoading);
  const customersSearchResult = useSelector(getCustomersSearchResult);
  const isResultsShown = isCustomersSearchLoading || customersSearchResult;

  const [inputValue, setInputValue] = useState(null);
  const clearInput = () => {
    setInputValue("");
    setTimeout(() => setInputValue(null));
  };

  const refSearch = useRef(null);

  useEffect(() => {
    if (!isResultsShown || !refSearch.current) return;

    const handleOutsideClick = (e) => {
      if (!refSearch.current.contains(e.target)) {
        dispatch(searchCustomers(null));
        clearInput();
      }
    };
    document.addEventListener("click", handleOutsideClick);

    return () => document.removeEventListener("click", handleOutsideClick);
  }, [refSearch.current, isResultsShown]);

  const handleChange = useCallback(
    debounce((value) => {
      dispatch(searchCustomers(value));
    }, 500),
    []
  );

  const handleCancel = () => {
    dispatch(searchCustomers(null));
    clearInput();
    onToggle();
  };

  return (
    <div className={styles.searchWrapper} ref={refSearch}>
      <SearchField
        className={cn(styles.search, { [styles.shown]: isOpen })}
        placeholder="Search by name, email, phone or VIN number..."
        onChange={handleChange}
        value={inputValue}
      />
      <div
        className={cn(styles.icon, { [styles.shown]: !isOpen })}
        onClick={onToggle}
      >
        <SearcherIcon />
      </div>
      <div
        className={cn(styles.button, { [styles.shown]: isOpen })}
        onClick={handleCancel}
      >
        Cancel
      </div>
      <div
        className={cn(styles.results, {
          [styles.hidden]: !isResultsShown,
        })}
      >
        {isCustomersSearchLoading ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : customersSearchResult &&
          Object.keys(customersSearchResult).length ? (
          Object.entries(customersSearchResult).map(([id, searchData]) => (
            <Item
              key={id}
              id={id}
              searchData={searchData}
              customer={customersById[id]}
              isSearchOpen={isOpen}
              onSearchToggle={onToggle}
              onClick={clearInput}
            />
          ))
        ) : (
          <EmptySearch
            wrapperClassName={cn(styles.noData)}
            image={<NoResultIcon2 width={100} height={100} />}
            title="No results found"
            message={
              "We can't find any results matching your search.\nPlease check spelling or try other keywords."
            }
          />
        )}
      </div>
    </div>
  );
}

function Item({
  id,
  searchData,
  customer,
  isSearchOpen,
  onSearchToggle,
  onClick,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const shops = useSelector(getShopsById);
  const selectedShopId = useSelector(getSelectedShopId);
  const isAllLocations = selectedShopId === ALL_LOCATIONS.id;

  const shop = shops[customer.shopId];
  const shopName = shop.name;
  const shopAddress = joinShopAddress(shop, false);

  const handleClick = () => {
    dispatch(searchCustomers(null));
    onClick();
    if (isSearchOpen) onSearchToggle();
    history.push(
      `/${selectedShopId}/customer/${id}${
        searchData.vehicleId ? `/vehicle/${searchData.vehicleId}` : ""
      }`
    );
  };
  return (
    <div className={styles.item} onClick={handleClick}>
      <div className={styles.icon}>
        {customer.isBusinessAccount ? <BusinessProfileIcon /> : <AvatarIcon />}
      </div>
      <div className={styles.data}>
        <div className={styles.name}>
          {searchData.property === "name" ? (
            <Highlight
              string={customer.name}
              index={searchData.index}
              length={searchData.length}
            />
          ) : (
            customer.name
          )}
        </div>
        <div className={styles.details}>
          {customer.email && (
            <>
              <div>
                {searchData.property === "email" ? (
                  <Highlight
                    string={customer.email}
                    index={searchData.index}
                    length={searchData.length}
                  />
                ) : (
                  customer.email
                )}
              </div>
              <div className={styles.dot} />
            </>
          )}
          {customer.phoneNumber && (
            <>
              <div>
                {searchData.property === "phoneNumber" ? (
                  <Highlight
                    string={customer.phoneNumber}
                    index={searchData.index}
                    length={searchData.length}
                  />
                ) : (
                  customer.phoneNumber
                )}
              </div>
              <div className={styles.dot} />
            </>
          )}
          {searchData.vehicleId && (
            <div>
              VIN:{" "}
              <Highlight
                string={customer.vehiclesData[searchData.vehicleId].vin}
                index={searchData.vinIndex}
                length={searchData.length}
              />
            </div>
          )}
        </div>
        {isAllLocations && (
          <div className={styles.shop}>
            <div>{shopName}</div>
            {shopAddress && (
              <>
                <div className={styles.dot} />
                <div>{shopAddress}</div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function Highlight({ string, index, length }) {
  return (
    <>
      {string.slice(0, index)}
      <span className={styles.highlight}>
        {string.slice(index, index + length)}
      </span>
      {string.slice(index + length)}
    </>
  );
}
