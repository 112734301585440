import React, { useState, useEffect } from "react";

import cn from "classnames";

import styles from "./Checkbox.module.scss";

export default function Checkbox({ isChecked, onChange, title }) {
  const [isSelected, setIsSelected] = useState(isChecked);
  const handleClick = () => {
    onChange(!isSelected);
    setIsSelected((x) => !x);
  };

  return (
    <div
      className={cn(styles.wrapper, { [styles.selected]: isSelected })}
      onClick={handleClick}
    >
      <div className={styles.checkbox} />
      <div className={styles.title}>{title}</div>
    </div>
  );
}
