import { PATHNAMES } from "../constants";

export const handleRouting = (shopId, history, pathname) => {
  if (pathname.includes(PATHNAMES.DASHBOARD)) {
    history.push(`/${shopId}/dashboard`);
  } else if (pathname.includes(PATHNAMES.VEHICLES)) {
    history.push(`/${shopId}/vehicles`);
  } else if (pathname.includes(PATHNAMES.DEVICE_INVENTORY)) {
    history.push(`/${shopId}/device-inventory`);
  } else {
    history.push(`/${shopId}/customers`);
  }
};
