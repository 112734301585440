import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Modal, ModalBody, ModalFooter } from "reactstrap";
import cn from "classnames";
import "../../../../custom-modal.css";
import classes from "../../../../styles/modal.module.scss";

import {
  CloseIcon,
  RemoveCustomerIcon,
  LoaderIcon,
} from "../../../../assets/icons";

import { getSelectedShopId } from "../../../shops/shops.selectors";
import {
  getSelectedCustomer,
  getRemoveModalShown,
  getRemovingCustomer,
} from "../../../customers/customers.selectors";
import {
  setRemoveModalShown,
  deleteCustomer,
} from "../../../customers/customers.slice";

export function RemoveCustomerModal() {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedShopId = useSelector(getSelectedShopId);
  const selectedCustomer = useSelector(getSelectedCustomer);
  const isRemoveModalShown = useSelector(getRemoveModalShown);
  const isRemovingCustomer = useSelector(getRemovingCustomer);

  const onToggle = () => dispatch(setRemoveModalShown(false));

  const handleDeleteCustomer = async () => {
    await dispatch(deleteCustomer({ id: selectedCustomer.id }));
    history.push(`/${selectedShopId}/customers`);
  };

  if (!isRemoveModalShown) return null;

  return (
    <div>
      <Modal
        isOpen={isRemoveModalShown}
        toggle={onToggle}
        contentClassName={cn(
          classes.Modal__Content,
          classes.Modal__Content__Confirm
        )}
        className={classes.Modal__Dialog}
        centered={true}
      >
        <div className={classes.Modal__Header}>
          <div className={classes.Modal__ConfirmTitle}>
            Disconnect Customer?
          </div>
          <CloseIcon className={classes.Modal__CloseIcon} onClick={onToggle} />
        </div>
        <ModalBody className={classes.Modal__Body}>
          <div className={classes.Modal__Confirm}>
            <RemoveCustomerIcon className={classes.Modal__RemoveVehicleIcon} />
            {selectedCustomer.vehicles.length ||
            !selectedCustomer.customerConsented ? (
              <div className={classes.Modal__ConfirmSubtitle}>
                You are trying to disconnect{" "}
                <span>{selectedCustomer.name}</span>.
                <br />
                If you have a Mojio Force subscription, it will be cancelled.
                Are you sure you want to disconnect the customer?
              </div>
            ) : (
              <div className={classes.Modal__ConfirmSubtitle}>
                Are you sure you want to disconnect{" "}
                <span>{selectedCustomer.name}</span>?
                <br />
                This cannot be undone.
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter
          className={cn(classes.Modal__Footer, classes.Modal__Footer__Confirm)}
        >
          <button
            className={cn(
              classes.Modal__CancelBtn__Confirm,
              classes.Modal__CancelBtn
            )}
            onClick={onToggle}
          >
            Cancel
          </button>
          <button
            className={cn(
              classes.Modal__SubmitBtn,
              classes.Modal__SubmitBtn__Confirm
            )}
            onClick={handleDeleteCustomer}
            disabled={isRemovingCustomer}
          >
            {isRemovingCustomer ? (
              <LoaderIcon className={classes.Modal__Loader} />
            ) : (
              "Yes, Disconnect"
            )}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
